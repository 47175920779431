.c-sidebar .c-sidebar-brand {
  background: #fff;
}

.mt-2rem {
  margin-top: 2rem;
}

.flex-1 {
  flex: 1;
}

.h-65p {
  height: 65%;
}

.single .react-select__control {
  height: 35px !important;
  min-height: 35px !important;
}

// разделитель между связанными инпутами
.input-bind:after {
  content: '-';
  position: absolute;
  right: -3px;
  top: 6px;
}
